.classroom-wrapper{
    /* padding: 0 20px; */ /*Padding is set in each subcontainer */ 
    /* margin-top: 60px; */
    /* min-height: 100vh;    */
    box-sizing: border-box;
    height: calc(100vh - 60px);
    overflow: hidden;
    /* height: 100vh; */
    /* padding-top: 60px; */
}
.classroom-main-container{
    display: flex;
    box-sizing: border-box;
    height: 100%;
}
.classroom-main-column-wrapper{
    flex-basis: 70%;
    overflow-y: scroll;
    box-sizing: border-box;
}
.classroom-side-column-wrapper{
    flex-basis: 30%;
    height: 100%;
    box-sizing: border-box;
}
.classroom-side-column-toggle-wrapper{
    height: 100%;
}
.classroom-error-loading-container{
    height: 100%;
    width:100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
}
.classroom-system-version-display-alert-container{
    position: fixed;
    top: 0px;
    z-index: 1111;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    opacity: 0%;
    transform: translateY(0px);
    pointer-events: none;
    transition: transform ease-in-out 0.3s, opacity ease-in-out 0.3s;
}
.classroom-system-version-display-alert-container.shown{
    transform: translateY(10px);
    opacity: 100%;
} 
@media screen and (min-width:1120px) {
    .classroom-wrapper{
        min-height: calc(100vh - 60px);
    }
    .classroom-main-container{
        max-width: 1440px;
        position: relative;
        margin: 0 auto;
        padding: 0px 10px;
        column-gap: 10px;
        min-height: inherit;
        /* padding: 0px 160px; */
    }
    .classroom-main-column-wrapper{
        border-radius: 4px 4px 0px 0px;
        min-height: inherit;
    }
}

@media screen and (max-width:1119px) {  
    /* .classroom-main-container{
        .classroom-main-column-wrapper{
            flex-basis: 0%;
            display: none
        }
        .classroom-side-column-wrapper{
            flex-basis: 100%;
        }
    } */
    .classroom-wrapper{
        height: unset;
        min-height: calc(100vh - 60px);
        overflow: unset;
    }
    .classroom-error-loading-container{
        height: 100vh;
    }
    .classroom-main-container{
        position: relative;
        width: calc(100% - 64px);
        margin: auto;
        min-height: inherit;
    }
    .classroom-main-column-wrapper{
        flex-basis: auto;
        overflow-y: unset;
        min-height: inherit;
        width: 100%;
        /* transition: ease-in-out width 100ms, ease-in-out opacity 100ms; */
    }
    .classroom-side-column-wrapper{
        position:absolute;
        bottom: 0px;
        width:100%;
        height: 100%;
    }
    .classroom-side-column-wrapper{
        transition: ease-in-out height 2000ms;
        height: 100%;
    }
    .classroom-side-column-wrapper.show-side-bar{
        pointer-events: all;
        z-index: 1101;
    }
    .classroom-side-column-wrapper.hide-side-bar{
        pointer-events: none;
    }
    .classroom-side-column-toggle-button-container{
        position:sticky;
        bottom:0px;
    }
    .classroom-side-column-toggle-button{
        height: 15px;
        cursor: pointer;
        pointer-events: all;
        transition: ease-in-out width 300ms;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        border-radius: 10px 10px 0px 0px ;
        margin: 0 auto;
    }
    .classroom-bottom-sheet-background{
        position: fixed;
        width: 100%;
        height: 100%;
        top:0px;
        left:0px;
        background: rgba(0, 0, 0, 0.575);
        z-index: 1101;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: fade-in 0.3s;
    }
    @keyframes fade-in {
        from { opacity: 0%;}
        to { opacity: 100%;}
    }
    .classroom-side-bottom-sheet{
        height: 5px;
        width: 100%;
        max-width: 100px;
        border-radius: 20px;
        margin: 0px 8px;
        box-sizing: border-box;
    }
    .classroom-side-column-toggle-button.hide-side-bar{
        width: 50%;
    }
    .classroom-side-column-toggle-button.show-side-bar{
        width: 100%;
    }
    .classroom-side-column-toggle-wrapper{
        transition: ease-in-out height 300ms;
        height: 0px;
        overflow: hidden;
    }
    .classroom-side-column-toggle-wrapper.show-side-bar{
        height: calc(100vh - 75px); /* 60 header, 15px toggle button */
    }
}
@media screen and (max-width:680px) {
    .classroom-main-container{
        width: calc(100% - 24px);
    }
}