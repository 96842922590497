.section-lesson-itinerary-pop-over-container{
    width: 100%;
    max-height: unset !important;
    max-width: 960px;
}
.section-lesson-itinerary-pop-over-video-container{
    margin: 0 auto;
    padding: 0px 10px;
    width: 100%;
    height: 100%;
    max-width: calc((100vh* 16 / 9) - 20px);/* includes padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.section-lesson-itinerary-pop-over-close-icon{
    visibility:hidden;
    top: 10px;
    left: 20px;
    border-radius: 5px;
}
.section-lesson-itinerary-pop-over-container:hover .section-lesson-itinerary-pop-over-close-icon{
    visibility: unset;
    background-color: rgba(0,0,0,0.3)
}
@media (hover:none) {
    .section-lesson-itinerary-pop-over-close-icon{
        visibility: unset;
    }    
    .section-lesson-itinerary-pop-over-close-icon{
        background-color: rgba(0,0,0,0.3)
    }
}
.section-lesson-itinerary-main-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.section-lesson-itinerary-section-title-and-numbers-container{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    column-gap: 24px;
    row-gap: 8px;
    padding: 0px 0px 16px 0px
}
.section-lesson-itinerary-section-in-numbers-container{
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: right;
    box-sizing: border-box;
    align-items: center;
    column-gap: 8px;
}
.section-lesson-itinerary-lessons-container{
    /* padding-bottom: 32px; */
    /* box-sizing: border-box; */
}
.section-lesson-itinerary-lesson-container{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 16px;
    padding-right: 16px;
    max-height:0px;
    overflow-y: hidden;
    border-bottom: solid 0px;
    transition: ease-in-out border-bottom 0.3s, ease-in-out max-height 0.3s, ease-in-out padding-bottom 0.3s, ease-in-out padding-top 0.3s;
}
.section-lesson-itinerary-lesson-container.show{
    max-height: 1000px;
    column-gap: 24px;
    row-gap: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: solid 1px;
}
.section-lesson-itinerary-lesson-container.no-border{
    border-bottom: solid 0px;
}
.section-lesson-itinerary-blurred-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.section-lesson-itinerary-lesson-title-and-icon-container{
    display: flex;
    box-sizing: border-box;
    flex-grow: 1;
    column-gap: 8px;
}
.section-lesson-itinerary-lesson-title-container{
    /* flex-grow: 1; */
}
.section-lesson-itinerary-lesson-icon-container{
    display: flex;
    align-items: center;
}
.section-lesson-itinerary-lesson-preview-and-lenght-container{
    display: flex;
    box-sizing: border-box;
    column-gap: 24px;
    flex-shrink: 0;
    flex-grow: 1;
    justify-content: right;
}
.section-lesson-itinerary-lesson-preview-container{
    cursor: pointer;
    flex-shrink: 0;
}
.section-lesson-itinerary-lesson-preview-container:hover{
    text-decoration: underline;
}
.section-lesson-itinerary-lesson-length{
    flex-shrink: 0;
}
.section-lesson-itinerary-show-more-wrapper{
    display: flex;
    box-sizing: border-box;
    justify-content: center;
}
.section-lesson-itinerary-show-more-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    column-gap: 8px;
    padding-top: 16px;
}
@media screen and (min-width: 681px) {
    .section-lesson-itinerary-system-version-display-alert-container{
        position:absolute;
        top: 10px;
        right: 10px;
        opacity: 0%;
        transform: translateX(0px);
        pointer-events: none;
        transition: transform ease-in-out 0.3s, opacity ease-in-out 0.3s;
    }
    .section-lesson-itinerary-system-version-display-alert-container.shown{
        transform: translateX(-10px);
        opacity: 100%;
    
    }   
}
@media screen and (max-width:680px) {
    .section-lesson-itinerary-system-version-display-alert-container{
        position:fixed;
        top: 10px;
        opacity: 0%;
        transform: translateY(0px);
        pointer-events: none;
        transition: transform ease-in-out 0.3s, opacity ease-in-out 0.3s;
    }
    .section-lesson-itinerary-system-version-display-alert-container.shown{
        transform: translateY(10px);
        opacity: 100%;
    
    }
}

