.refresh-browser-error-wrapper{
}
.refresh-browser-error-main-container{
    max-width: 1440px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.refresh-browser-error-header-container{
    height: 60px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
}
.refresh-browser-error-logo{
    height: 30px;
    cursor: pointer;
    display: block;
    text-decoration: none !important;
}
.refresh-browser-error-content-container{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    padding: 40px 0px;
}
.refresh-browser-error-animation-container{
    width: 200px;
    margin: 0 auto;
}
.refresh-browser-error-button-container{
    padding-top: 40px;
}
.refresh-browser-error-footer-container{
    width: 100%;
    height: 60px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
}
@media screen and (min-width:1120px) {
    .refresh-browser-error-main-container{
        padding: 0px 40px;
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .refresh-browser-error-main-container{
        padding: 0px 32px;
    }
}
@media screen and (max-width:680px) {
    .refresh-browser-error-main-container{
        padding: 0px 12px;
    }
}