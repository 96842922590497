.email-subscription-list-main-container{
    min-height: 100vh;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.email-subscription-list-modal-container{
    width: 380px;
}
.email-subscription-list-email-loading-container{
    padding: 20px;
}
.email-subscription-list-email-identifying-container{
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    row-gap: 20px;
}
.email-subscription-list-preferences-container{
    padding: 20px;
}
.email-subscription-list-preferences-menu-inline-field-group-container{

}
.email-subscription-list-preferences-menu-field-value-button-container{
    display: flex;
    box-sizing: border-box;
    justify-content: flex-end;
}
.email-subscription-list-preferences-menu-field-load-button-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
}
