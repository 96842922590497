.content-hero-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
}
.content-hero-main-container{
    min-height: calc(100vh - 60px); /* header-height: 60px */
}
.content-hero-chips-container{
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
    padding: 0px 8px;
}
.content-hero-title-and-author-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 8px;
    align-items: center;
    text-align: center;
}
.content-hero-author-container{
    text-align: center;
}
.content-hero-consumable-date-widget-container{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
}
.content-hero-description-container{
    max-width: 800px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 16px;
    align-items: center;
}
.content-hero-button-container{
    display: flex;
    column-gap: 16px;
}
.content-hero-features-container{
    column-gap: 16px;
    display: grid;
    grid-template-columns: auto auto auto auto;
}
.content-hero-feature{
    display: flex;
    align-items: center;
    column-gap: 8px;
}
.content-hero-feature-values-container{
    display: flex;
}
@media screen and (min-width: 1120px) {
    .content-hero-main-container{
        display: flex;
        width:100%;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        max-width: 1440px;
        padding: 12px 160px 0px 160px;
        /* minimum row gap due to justify-content att */
        row-gap: 48px;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px) {
    .content-hero-main-container{
        display: flex;
        width:100%;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 12px 32px 0px 32px;
        /* could be removable */
        row-gap: 40px;
    }
    .content-hero-features-container{
        grid-template-columns: auto auto auto auto;
        row-gap: 16px;
        /* flex-wrap: wrap; */
        /* justify-content: center; */
    }
    /* .content-hero-feature{
        flex-basis: calc(50% - 8px);
    } */
}
@media screen and (max-width: 680px) {
    .content-hero-main-container{
        display: flex;
        width:100%;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 24px 12px 72px 12px;
        /* could be removable */
        row-gap: 40px;
    }
    .content-hero-features-container{
        grid-template-columns: auto;
        row-gap: 16px;
        /* flex-wrap: wrap; */
        /* justify-content: center; */
    }
    .content-hero-feature{
    }
}