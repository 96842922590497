.class-content-lecture-main-container{
    width: 100%;
}
.class-content-lecture-video-container{
    /* padding-top: 5px; */
    padding-bottom: 20px;
    background: linear-gradient(180deg, black calc(100% - 20px), transparent 100%)
}
.class-content-lecture-title-container{
    padding: 40px 20px 20px 20px;
}
.class-content-lecture-except-video-and-title-container{
    width: 80%;
    margin: auto;
}
.class-content-lecture-video{
    height: calc(100vw * 9 / 16);
}
.class-content-lecture-live-container{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    row-gap: 20px;
}
.class-content-lecture-reading-container-main{
    padding-top: 40px;
}
.class-content-lecture-download-container{
    padding-top: 40px;
}
.class-content-lecture-key-points-container{
    padding-top: 40px;
}
.class-content-lecture-actions-container{
    padding-top: 40px;
}
.class-content-lecture-complete-button{
    padding-top: 40px;
}

.class-content-lecture-centered-item{
    width: 100%;
    display: flex;
    justify-content: center;
}

/* 
    Reading Lecture styles
    html sanitizing prefixes every id and name attribute with 'user-content'
*/
.class-content-lecture-reading-container-main *:first-child{
    margin-top: 0 !important;
}
.class-content-lecture-reading-container-main h5{
    margin-block-start: 2em;
    margin-block-end: 0.5em !important;
}
.class-content-lecture-reading-container-main hr{
    margin-bottom: 2em;
}
.class-content-lecture-reading-container-main h6{
    margin-block-start: 1em !important;
    margin-block-end: 0.5em !important;
}
.class-content-lecture-reading-container-main p{
    margin-block-end: 1em !important;
}
.class-content-lecture-reading-container-main img{
    max-width: 80%;
    margin-top: 2em;
    margin-bottom: 1em;
}
.class-content-lecture-reading-container-main img[src*="#center"]{
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    float: none!important;
}
/* #user-content-img-footnote{
    margin-left: auto;
    margin-right: auto;
    font-style: italic;
    max-width: 60%;
    color: #888;
    font-size: 0.8em;
    margin-block-end: 2em !important;
} */
.class-content-lecture-reading-container-main .img-footnote{
    margin-left: auto !important;
    margin-right: auto !important; 
    font-style: italic !important;
    max-width: 80% !important;
    color: #888 !important;
    font-size: 0.8em !important;
    margin-block-end: 2em !important;
}
/* All li except the first one */
.class-content-lecture-reading-container-main li:not(:nth-child(1)){
    padding-top: 10px;
}
.class-content-lecture-reading-container-main *:last-child{
    margin-bottom: 0 !important;
}
@media screen and (min-width: 1120px) {
    .class-content-lecture-video{
        max-height: 450px;
    }
    .class-content-lecture-title-container{
        padding: 40px 20px 20px 20px;
    }
    .class-content-lecture-except-video-and-title-container{
        width: 80%;
        margin: auto;
    }
    .class-content-lecture-live-container{
        padding: 20px;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px) {
    .class-content-lecture-video{
        max-height: 450px;
    }
    .class-content-lecture-live-container{
        padding: 20px;
    }
    @media screen and (orientation: landscape) {
        .class-content-lecture-video{
            max-height: calc(100vh - 60px);
        }
    }
    /* 
        Reading Lecture styles
        html sanitizing prefixes every id and name attribute with 'user-content'
    */
}
@media screen and (max-width: 680px) {
    .class-content-lecture-video{
        /* min-height: 200px; */
    }
    .class-content-lecture-title-container{
        padding: 40px 10px 20px 10px;
    }
    .class-content-lecture-except-video-and-title-container{
        width: 100%;
        padding: 0px 10px;
        box-sizing: border-box;
    }
    .class-content-lecture-live-container{
        padding: 20px 10px;
    }
    .class-content-lecture-key-points-container ul{
        padding-inline-start: 20px;
    }
    /* 
        Reading Lecture styles
        html sanitizing prefixes every id and name attribute with 'user-content'
    */
    .class-content-lecture-reading-container-main img{
        max-width: 90%;
    }
    .class-content-lecture-reading-container-main .img-footnote{
        max-width: 90% !important;
    }
    .class-content-lecture-reading-container-main ul{
        padding-inline-start: 20px;
    }
    @media screen and (orientation: landscape) {
        .class-content-lecture-video{
            max-height: calc(100vh - 60px);
        }
    }
}