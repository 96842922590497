.log-and-sign-in-main-container{
    min-height: 100vh;
    padding-top: 80px;
    padding-bottom: 80px;
    align-items: center;
}
.log-and-sign-in-modal-container{
    width: 380px;
}
.log-and-sign-in-modal{
    box-sizing: border-box;
    overflow: hidden;
    position:relative;
    transition: height ease-in-out 0.3s !important;
}
.log-in-sign-in-modal-inner-container{
    padding: 20px 20px 0px 20px;
}