.classroom-main-column-container{
    /* height: 100%; */
    /* overflow-y: auto; */
    min-height: inherit;
}
.classroom-main-column-header{
    height: 60px;
    position:sticky;
    top:0px;
    /* Over videoPlayer with z-index:1 */
    z-index: 2;
    transition: height ease-in-out 0.2s, opacity ease-in-out 0.2s;
}
.classroom-main-column-content{
    /* padding is set in the component */
    /* position:relative */
}
.classroom-main-column-add-user-note-wrapper{
    position: sticky;
    width: 100%;
    height: 50px;
    bottom: 0px;
    display: flex;
    justify-content: end;
    box-sizing: border-box;
}
.classroom-main-column-add-user-note-container{
    position: relative;
    height: 100%;
    width: 50%
}
.classroom-main-column-footer{
    padding-top: 60px;
}
@media screen and (min-width:1120px) {
    .classroom-main-column-container{
        /* height: 100%; */
    }
}
@media screen and (max-width:1119px) {
    .classroom-main-column-content{
        /* Header 60px */
        /* min-height: calc(100% - 60px);  */
        padding-bottom: 20px;
    }
    .classroom-main-column-add-user-note-wrapper{
        /* top:0px; */
        /* height: 100vh; */
    }
    .classroom-main-column-add-user-note-container{
        width: 100%
    }
}