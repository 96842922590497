.email-subscription-list-main-container{
    min-height: 100vh;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.email-subscription-list-modal-container{
    width: 380px;
}

