.update-email-wrapper{}
.update-email-main-container{
    min-height: 100vh;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.update-email-paper{
    box-sizing: border-box;
    padding: 20px;
    width: 380px;
}
.update-email-email-container{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    column-gap: 8px;    
}
.update-email-email{
    line-break: anywhere;
    font-style: italic;
    text-align: center;
}
.update-email-animation{
    width:100px;
    margin: 0 auto;
}
.update-email-title{
    font-weight: bold !important;
}
.update-email-msg{
    white-space: pre-line;
    padding-bottom: 20px;
}
.update-email-button-container{
    text-align: center;
}
.update-email-support-container{
    text-align: center;
    padding-top: 20px;
}
.update-email-support-link{
    margin: 0 auto;
}