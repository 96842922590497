.set-billing-address-main-container{
    box-sizing: border-box;
    min-height: 100vh;
    padding-top: 80px;
    padding-bottom: 80px;
    /* background-color: #fafafa; */
}
.set-billing-address-content-container{
    /* border: 1px solid rgb(182, 182, 182); */
    object-fit: contain;
    /* Box Shadow */
    /* box-shadow: 1px 1px 10px rgba(182, 182, 182, 0.3); */
}