.content-error-wrapper{
    min-height: 100vh;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}
.content-loading-wrapper{
    min-height: 100vh;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}
.content-banner-action-reminder-wrapper{
    position: sticky;
    bottom:-1px; /*This is set to trigger useDetectSticky. Source: https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/ */
    right:0px;
    opacity: 0;
    transition: opacity ease-in-out .3s, background-color ease-in-out .3s;
}
.content-banner-action-reminder-wrapper.show{
    opacity: 1;
}
.nest-sections-top-container{
    padding-bottom: 36px;
}
.nest-sections-bottom-container{
    padding-top: 36px;
}