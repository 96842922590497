:root{
    --primary-color: #4355B9;
    --primary-lighter-color: #DEE0FF;
    --secondary-color: #B90063;
    --secondary-lighter-color: #ffD9E2;
}

.app-loading-container{
    height: 100vh;
    width: 100%;
}
.app-botton-info-banner-container{
    position: sticky; 
    bottom: 0px; 
    width: 100%
}

/* StyledTypography Component Classes */
ul.styled-list.primary-marker > li::marker{
    color: var(--primary-color)
}
ul.styled-list.secondary-marker > li::marker{
    color: var(--secondary-color)
}

li.styled-list-item:not(:last-child){
    padding-bottom: 1em;
}

span.highlight{
    padding-inline: 4px;
    background-color: var(--primary-lighter-color);   
}
span.highlight.secondary{
    background-color: var(--secondary-lighter-color);
}
/*  */