.page-not-found-main-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0px;
    min-height: 100vh;
    box-sizing: border-box;
    /* background-color: #fafafa; */
}
.page-not-found-paper-container{
    width:380px;
    padding: 20px;
    box-sizing: border-box;
    /* border: solid 1px #eeee;
    border-radius: 5px;
    box-shadow: 1px 1px 10px rgba(182, 182, 182, 0.3); */
}
.page-not-found-title-container{
    padding-bottom:20px;
}
.page-not-found-msg{
   padding-bottom:10px;
}
.page-not-found-error-container{
    padding: 10px;
    background-color: #fafafa ;
    border-radius: 5px;
    color: #BDBDBD; 
}
.page-not-found-error-msg{
    padding:10px;
}
.page-not-found-error-code{
    display: inline;
}