.terms-content-wrapper{
    /* header */
     padding-top: 60px ;
    min-height: 100vh;
    margin: 0 auto;
}
.terms-content-title{
    text-align: center;
    margin-block-end: 1.5em !important;
}
.terms-content-date{
    color: rgba(0, 0, 0, 0.4);
}
@media screen and (min-width:1120px) {
    .terms-content-wrapper{
        max-width: 1440px;
        padding: 160px 0px;
    }
    .terms-main-content-wrapper{
        padding: 0px 160px;
        box-sizing: border-box;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px){
    .terms-content-wrapper{
        padding: 160px 0px
    }
    .terms-main-content-wrapper{
        padding: 0px 32px;
        box-sizing: border-box;
    }
}
@media screen and (max-width: 680px){
    .terms-content-wrapper{
        padding: 80px 0px;
 
    }
    .terms-main-content-wrapper{
        padding: 0px 12px;
        box-sizing: border-box;
    }
}
